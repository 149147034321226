import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS,  HttpClientModule,  provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BaseChartDirective } from 'ng2-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgChatModule } from 'ng-chat';

import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HttpErrorInterceptor } from 'src/app/_helpers/interceptors/http-error.interceptor';
import { environment } from 'src/app/models/constants';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'; // If you're using Realtime Database
import { AngularFireAuthModule } from '@angular/fire/compat/auth'; // If you're using Authentication
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({ declarations: [],
    exports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatTabsModule,
        MatMenuModule,
        MatExpansionModule,
        MatButtonModule,
        MatRadioModule,
        MatCardModule,
        MatDialogModule,
        MatSelectModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatCheckboxModule,
        // BaseChartDirective,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        // NgChatModule,
        AngularFireDatabaseModule, // If you're using Realtime Database
        AngularFireAuthModule, // If you're using Authentication
        MatBadgeModule,
        CommonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        NgxSpinnerModule
    ], imports: [BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatTabsModule,
        MatMenuModule,
        MatExpansionModule,
        MatButtonModule,
        MatRadioModule,
        MatCardModule,
        MatDialogModule,
        MatSelectModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatCheckboxModule,
        // BaseChartDirective,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        //  provideFirebaseApp(() => initializeApp(environment.firebase)),
        // provideMessaging(() => getMessaging()),
        // provideAuth(() => getAuth()),
        // provideDatabase(() => getDatabase()),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule, // If you're using Realtime Database
        AngularFireAuthModule, // If you're using Authentication
        MatBadgeModule,
        CommonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        NgxSpinnerModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        MessagingService,
        AsyncPipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
