import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { catchError } from 'rxjs/operators';
// import * as privateKey from '../../assets/font/privateKey.pem';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  CryptoJS = require("crypto-js");
  base64URL = require("base64url");
  // fernet = require('./fernet');
  secret: any;
  aesToken: any;
  constructor(private http: HttpClient) {
   }

   loadPEMFile() {
    // Replace 'assets/your.pem' with the correct path to your .pem file.
    // return this.http.get(environment.privateKey, { responseType: 'text' })
  }

   makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    console.log(result.toString());
    return result.toString();
}

   getAesToken(payload) {
    const derivedKey = decodeURIComponent(localStorage.getItem('salt'));
    let _key = CryptoJS.enc.Utf8.parse(derivedKey);
    let _iv = CryptoJS.enc.Utf8.parse(derivedKey);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(payload), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    var tok = encrypted.toString();
    this.aesToken = tok;console.log('aesToken:-   '+tok);
    return tok;
  }
  decryptUsingAES256() {
    const derivedKey = decodeURIComponent(localStorage.getItem('salt'));
    let _key = CryptoJS.enc.Utf8.parse(derivedKey);
    let _iv = CryptoJS.enc.Utf8.parse(derivedKey);

    var decrypted = CryptoJS.AES.decrypt(
      this.aesToken, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      console.log(decrypted);
  }

   base64url(source: any) {
    let encodedSource = this.CryptoJS.enc.Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  encodeToken(payload:any) {
    var header = {
      "alg": "RS256",
      "typ": "JWT"
    };

    var stringifiedHeader = this.CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = this.base64url(stringifiedHeader);

    var stringifiedData = this.CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    var encodedData = this.base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;
    return token;
  }

  signToken(payload:any,key:string) {
    var secret = key;
    let token:any = this.encodeToken(payload);
    console.log("encodeToken:-   "+token);

    var signature:any = this.CryptoJS.HmacSHA256(token, secret);
    signature = this.base64url(signature);

    var signedToken = token + "." + signature;
    console.log("signedToken:-   "+signedToken);
    return signedToken;
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', localStorage.getItem('bearerToken')); 
  }

  public get(url: string, options?: any, headers?: any): Observable<any> {
   try {
    headers = new HttpHeaders();
      headers = headers
      // .set('Access-Control-Allow-Origin', 'http://15.206.116.221/api/v2')
      .set('Content-Type', 'application/json')
      // .set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS, PATCH')
      .set('Authorization', `Bearer ${localStorage.getItem('bearerToken').toString()}`)
    // .set('Access-Control-Allow-Headers', 'Access-Control-Allow-Origin, Content-Type', 'Access-Control-Allow-Methods', 'Authorization');
    return this.http.get(url, { headers: url.toLowerCase().includes('d/practiceareas/get') ? null : headers}).pipe(
      catchError(error => {
        // Handle the error here
        console.error('API Error:', error);
        return throwError(error);
      })
    );
   } catch(error) {
    console.log(error);
   }
  }

  public post(url: string, data: any, options?: any, headers?: any): Observable<any> {
    try {
      data = ((url.toLowerCase().includes('discussion_board/create') && data['discussion_type'] && data['discussion_type'].toLowerCase() == 'quiz') || ('files' in data) || url.toLowerCase().includes('services/edit_common_user_profile') || ('file' in data) || ('licenseCertificate' in data) || ('photos' in data) || ('image' in data) ||  ('images' in data) || ('banners[]' in data) || ('videos' in data) || ('cover_pic' in data) || ('profile_pic' in data) || url.toLowerCase().includes('referral/send_referral') || url.toLowerCase().includes('groups/create_group') || data.dataFile || url.toLowerCase().includes('getinvited') || url.toLowerCase().includes('getuserprofile') || url.toLowerCase().includes('jobapply') || url.toLowerCase().includes('doctor_services/update_profile')) ? this.jsonToFormData(data) : (url.toLowerCase().includes('discussion_board') && !('images' in data)) ? data : data;
      headers = new HttpHeaders();
      console.log(data);
      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('bearerToken').toString()}`)
      return this.http.post(url, data, { headers: (url.toLowerCase().includes('getinvited') || url.toLowerCase().includes('getuserprofile') && !url.toLowerCase().includes('networks/get_explore_list_v2') && !url.toLowerCase().includes('networks/get_all_networks') && !url.toLowerCase().includes('networks/user_follow') && !url.toLowerCase().includes('networks/user_unfollow') && !url.toLowerCase().includes('networks/get_all_networks') && !url.toLowerCase().includes('feed') && !url.toLowerCase().includes('discussion_board')) ? null : headers });
    } catch (error) {
      console.log(error);
    }
  }

  public patch(url: string, data: any, options?: any, headers?: any): Observable<any> {
    try {
      data = ((url.toLowerCase().includes('discussion_board/create') && data['discussion_type'] && data['discussion_type'].toLowerCase() == 'quiz') || ('files' in data) || ('file' in data) || ('licenseCertificate' in data) || ('photos' in data) || ('image' in data) ||  ('images' in data) || ('banners[]' in data) || ('videos' in data) || ('cover_pic' in data) || ('profile_pic' in data) || url.toLowerCase().includes('referral/send_referral') || url.toLowerCase().includes('groups/create_group') || data.dataFile || url.toLowerCase().includes('getinvited') || url.toLowerCase().includes('getuserprofile') || url.toLowerCase().includes('jobapply') || url.toLowerCase().includes('doctor_services/update_profile')) ? this.jsonToFormData(data) : (url.toLowerCase().includes('discussion_board') && !('images' in data)) ? data : data;
      headers = new HttpHeaders();
      console.log(data);
      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('bearerToken').toString()}`)
      return this.http.patch(url, data, { headers: (url.toLowerCase().includes('getinvited') || url.toLowerCase().includes('getuserprofile') && !url.toLowerCase().includes('networks/get_explore_list_v2') && !url.toLowerCase().includes('networks/get_all_networks') && !url.toLowerCase().includes('networks/user_follow') && !url.toLowerCase().includes('networks/user_unfollow') && !url.toLowerCase().includes('networks/get_all_networks') && !url.toLowerCase().includes('feed') && !url.toLowerCase().includes('discussion_board')) ? null : headers });
    } catch (error) {
      console.log(error);
    }
  }

  public discussionPost(url: string, data: any, options?: any): Observable<any> {
    data = this.jsonToFormData(data);
    return this.http.post(url, data, options);
  }

  public postComment(url: string, data: any, options?: any): Observable<any> {
    data = this.jsonToFormData(data);
    return this.http.post(url, data, options);
  }

  public pollPost(url: string, data: any, options?: any): Observable<any> {
    data = this.jsonToFormData(data);
    return this.http.post(url, data, options);
  }

  public deletePost(url: string, data: any, options?: any): Observable<any> {
    const opt = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: (url.toLowerCase().includes('groups/delete') || url.toLowerCase().includes('feed/delete')) ? options : new HttpParams({ fromObject: options }),
    }
    opt.headers = opt.headers.set('Authorization', `Bearer ${localStorage.getItem('bearerToken').toString()}`)
    return this.http.post(url, data, options);
  }

  public put = (url: string, data: any, options?: any): Observable<any> =>
    this.http.put(url, data, options);

  public delete(url: string, options?: any): Observable<any> {
    const opt = {
      headers: new HttpHeaders({}),
      body: (url.toLowerCase().includes('diagnostic_services/delete_test') || url.toLowerCase().includes('practice_area/delete') || url.toLowerCase().includes('feed/delete_comment') || url.toLowerCase().includes('discussion_board/delete') || url.toLowerCase().includes('groups/delete') || url.toLowerCase().includes('feed/delete')) ? options : new HttpParams({ fromObject: options }),
    }
    opt.headers = opt.headers.set('Authorization', `Bearer ${localStorage.getItem('bearerToken').toString()}`)
    return this.http.delete(url, opt);
  }

  getPdf(url) {
    return this.http.get(url, {
      responseType: 'blob',observe: 'response'
    });
  }

  public jsonToFormData(item: any): FormData {
    let formData = new FormData();
    for (const key in item) {
      if (key == 'files' || key == 'images' || key == 'banners[]' || key == 'videos' || key == 'images') {
        for (const file in item[key]) {
          if (file) formData.append(key, item[key][file]);
          else formData.append(key, item[key] ?? '');
        }
      } else if (key == 'file' || key == 'profile_pic' || (key == 'cover_picture' && item['cover_picture']) || (key == 'display_picture' && item['display_picture']) || key == 'licenseCertificate' || key == 'dataFile' || key == 'cover_pic' || key == 'image') {
          if (item[key].name) formData.append(key, item[key], item[key].name);
          else formData.append(key, item[key] ?? '');
      }
       else formData.append(key, item[key] ?? '');
    }
    return formData;
  }
}
