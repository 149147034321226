<div class="right-sidebar">
  <!-- Trending Podcast Section -->

  <div *ngIf="activeAction !== 'more'">
    <div class="container">
      <div class="header">
        Trending Podcasts
        <span>
          <img src="../../../../assets/new_feed/tas.png" alt="Trending Icon" />
        </span>
      </div>
      <div class="content1">
        <div class="podcast-card">
          <img [src]="podcastImage" alt="Podcast Image" />
          <div class="podcast-title">{{ podcastName }}</div>
          <div class="stats">
            <span>
              <img src="../../../../assets/new_feed/pod_1.png" alt="Episodes Icon" />
              {{ pc_episode_count }}
            </span>
            <span>
              <img src="../../../../assets/new_feed/pod_2.png" alt="Duration Icon" />
              {{ podcastEpisodeDuration }}
            </span>
            <span>
              <img src="../../../../assets/new_feed/pod_3.png" alt="Views Icon" />
              {{ pc_views }}
            </span>
          </div>
          <a class="view-all" href="#">View all</a>
        </div>
      </div>
    </div>

    <!-- Trending on Feed Section -->
    <div class="container mt-2">
      <div class="header">Trending on Feed</div>
      <div class="content1 feed-links">
        <a *ngFor="let tag of trending_tags" (click)="onSearch(tag.hashtag)">
          {{ tag.hashtag }}
        </a>
      </div>
    </div>
  </div>


  <div class="sidebar-menu" *ngIf="activeAction === 'more'">
    <ul>
      <li [ngClass]="{ active: selectedOption === 'My Profile' }" (click)="selectOption('My Profile')">
        My Profile
      </li>
      <li [ngClass]="{ active: selectedOption === 'About Us' }" (click)="selectOption('About Us')">
        About Us
      </li>
      <li [ngClass]="{ active: selectedOption === 'Privacy Settings' }" (click)="selectOption('Privacy Settings')">
        Privacy Settings
      </li>
      <li [ngClass]="{ active: selectedOption === 'Account Preference' }" (click)="selectOption('Account Preference')">
        Account Preference
      </li>
      <li [ngClass]="{ active: selectedOption === 'Terms and Conditions' }"
        (click)="selectOption('Terms and Conditions')">
        Terms and Conditions
      </li>
      <li [ngClass]="{ active: selectedOption === 'Rate Us' }" (click)="selectOption('Rate Us')">
        Rate Us
      </li>
      <li [ngClass]="{ active: selectedOption === 'Sign In Settings' }" (click)="selectOption('Sign In Settings')">
        Sign In Settings
      </li>
      <li [ngClass]="{ active: selectedOption === 'Deactivate Account' }" (click)="selectOption('Deactivate Account')">
        Deactivate Account
      </li>
      <li [ngClass]="{ active: selectedOption === 'Support' }" (click)="selectOption('Support')">
        Support
      </li>
      <li [ngClass]="{ active: selectedOption === 'Logout' }" (click)="selectOption('Logout')">
        Logout
      </li>
    </ul>
  </div>


</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
  <b style="color: white"> Loading... </b>
</ngx-spinner>