import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchService } from 'src/app/modules/home/search.service';
import { FeedService } from 'src/app/modules/home/services/feed.service';
import { AuthService } from 'src/app/services/auth.service';
import { NewRateUsComponent } from '../../new-rate-us/new-rate-us.component';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent implements OnChanges, OnInit {
  @Input() activeAction: string = '';

  // Podcast Properties
  podcastName: string = '';
  podcastImage: string = '';
  podcastDescription: string = '';
  podcastEpisodeCount: number = 0;
  podcastEpisodeDuration: string = '';
  podcastWatchers: string = '';
  pc_episode_count: number = 0;
  pc_views: number = 0;

  // Trending Properties
  trending_tags: { hashtag: string }[] = [];
  trending_events: any[] = [];

  // Sidebar Menu
  selectedOption: string = 'My Profile';
  sidebarMenu: string[] = [
    'My Profile',
    'About Us',
    'Privacy Settings',
    'Account Preference',
    'Terms and Conditions',
    'Rate Us',
    'Sign In Settings',
    'Deactivate Account',
    'Support',
    'Logout',
  ];

  // Loading State
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private feedService: FeedService,
    private authService: AuthService,
    private searchService: SearchService,
    private router: Router,
        private _snackBar: MatSnackBar,
    

  ) { }

  ngOnInit(): void {
    // Initial data fetch if required
    if (this.activeAction !== 'more') {
      this.getTrendingData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeAction && this.activeAction !== 'more') {
      this.getTrendingData();
    }
  }

  /**
   * Selects a sidebar option and performs associated actions.
   * @param option - The selected sidebar option.
   */
  selectOption(option: string): void {
    this.selectedOption = option;
    // alert(`Selected option: ${option}`);

    switch (this.selectedOption) {
      case 'My Profile':
        this.router.navigate(['/moreoptions/profile-edit']);
        break;
      case 'About Us':
        this.router.navigate(['/about']);
        break;
      case 'Privacy Settings':
        // this.router.navigate(['/settings']);

        this.router.navigate(['/moreoptions/privacy-settings']);

        break;
      case 'Account Preference':
        this.router.navigate(['/moreoptions/account-preference']);
        break;
        case 'Rate Us':
          // this.router.navigate(['/moreoptions/rate-us']);
          this.openDialog(NewRateUsComponent);
          break;
      case 'Sign In Settings':
        this.router.navigate(['/moreoptions/signin-settings']);
        break;
        
        case 'Logout':
          this.logOut();
          break;
      default:
        console.warn('Unknown action:', this.selectedOption);

    }
    // Perform navigation or other actions here
  }

  /**
   * Initiates a search using the selected hashtag.
   * @param hashtag - The selected hashtag for the search.
   */
  onSearch(hashtag: string): void {
    this.searchService.setSearchTerm(hashtag); // Update search term
    console.log('Searching for:', hashtag);
  }
  openDialog(dialogType: any) {
    const dialogRef = this.dialog.open(dialogType, {
      // height: '500px',
      // width: '60%',
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((res) => {
      // this.getMyGroups();
    });
  }
  /**
   * Fetches trending data for the right sidebar, including podcasts and tags.
   */
  async getTrendingData(): Promise<void> {
    const payload = {
      uid: this.authService.userValue?.uid, // Ensure `uid` exists in `userValue`
      date: new Date().toISOString().split('T')[0], // Format date as YYYY-MM-DD
    };

    this.isLoading = true;
    this.spinner.show();

    try {
      const response = await (await this.feedService.getTendingDetails(payload)).toPromise();

      if (response?.data) {
        console.log('Trending data:', response.data);

        // Assign trending data
        this.trending_tags = response.data.trending_tags || [];
        this.trending_events = response.data.upcoming_events || [];

        const trendingPodcast = response.data.trending_podcast?.[0];
        if (trendingPodcast) {
          this.podcastName = trendingPodcast.pc_title || 'Unknown Podcast';
          this.podcastImage =
            trendingPodcast.pc_thumbnail ||
            './../../../assets/new_feed/heart.png';
          this.pc_episode_count = trendingPodcast.pc_episode_count || 0;
          this.pc_views = trendingPodcast.pc_views || 0;
          this.podcastEpisodeDuration = trendingPodcast.pc_duration || 'N/A';
        }
      }

      this.isLoading = false;
      this.spinner.hide();
    } catch (error) {
      this.isLoading = false;
      this.spinner.hide();
      console.error('Failed to fetch trending data:', error);
    }
  }
  async logOut() {    // Method to logout from the application
    let payload = {
      uid: this.authService.userValue.uid,
      token: this.authService.userValue['td']
    };
    console.log('logout payload', payload);
    this.spinner.show();
    (await this.authService.logout(payload)).subscribe((res) => {
      this.spinner.hide();
      this.openSnackBar(res.message, '');
      if (res.status) {
        localStorage.clear();
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });
      }
    }, error => {
      this.spinner.hide();
    });
  }
  openSnackBar(message: string, action: string): void {
    // Method to open alert messages
    this._snackBar.open(message, action, { duration: 3000 });
  }
}
