<div class="signup-container">
    <div class="left-section">
      <div class="slider">
        <img [src]="currentImage" alt="Sliding Image" />
  
        <div class="indicators">
          <span *ngFor="let img of images; let i = index" [class.active]="i === imageIndex" (click)="goToSlide(i)">
          </span>
        </div>
  
      </div>
  
  
      <div>
        <h1>Welcome to <strong style="color: white;">Nushift Connect</strong></h1>
        <p style="color: white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius.</p>
      </div>
  
      <div class="networking">
        <h2>Networking</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.</p>
      </div>
    </div>
  
    <div class="right-section">
      <div class="one">
        <div class="logo">
          <img src="assets/NC_LOGO.png" alt="Nushift Connect Logo" />
        </div>
      </div>
 
    </div>
  
  
  </div>