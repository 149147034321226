import { Component, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-login',
  standalone: true,
  imports: [FormsModule, BrowserModule,],
  templateUrl: './new-login.component.html',
  styleUrl: './new-login.component.scss'
})
export class NewLoginComponent {
  userInput: string = '';
  otpInput: string = '';
  passwordInput: string = '';
  confirmPasswordInput: string = '';
  isDeleted = false; // Flag to toggle views
  showOTPScreen = false;
  resendTimer = 59;
  interval: any; // To store the timer interval
  isResendDisabled = true;
  images: string[] = [
    'assets/main_slider_01.png',
    'assets/main_slider_02.png',
    'assets/main_slider_03.png'
  ];
  imageIndex: number = 0;
  currentImage: string = this.images[0];
  private intervalId: any;
  currentView: 'login' | 'register' | 'forgot' | 'verify-otp' | 'login-password' | 'set-new-password' = 'login';
  emailOrPhonePattern: RegExp = /^(?:\+?[0-9]{10,15}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  otp1: string = '';
  otp2: string = '';
  otp3: string = '';
  otp4: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.startImageSlider();
  }
  /** Switch between different views */
  switchView(view: 'login' | 'register' | 'forgot' | 'verify-otp' | 'login-password' | 'set-new-password') {
    if (view === 'verify-otp' || view === 'login-password') {
      if (!this.userInput || !this.emailOrPhonePattern.test(this.userInput.trim())) {
        alert('Please enter a valid email or mobile number.');
        return;
      }
    }
    else if(view === 'register'){
      this.router.navigate(['/sign-up']);
      return;

    }
    this.currentView = view;
    // this.router.navigate(['/sign-up']);

  }
  
  signupAction(){
    alert('signupAction');
    // this.router.navigateByUrl('about');
  }
  /** Start automatic image slider */
  startImageSlider(): void {
    this.intervalId = setInterval(() => {
      this.imageIndex = (this.imageIndex + 1) % this.images.length;
      this.currentImage = this.images[this.imageIndex];
    }, 10000);
  }

  /** Manually navigate to a specific image */
  goToSlide(index: number): void {



    alert(index);
    if (index >= 0 && index < this.images.length) {
      this.imageIndex = index;
      this.currentImage = this.images[index];
      this.resetSlider(); // Resets auto-slide when the user clicks on an indicator
    }
  }
  startResendTimer() {
    let interval = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer === 0) {
        clearInterval(interval);
        this.isResendDisabled = false;
      }
    }, 1000);
  }

  /** Reset the slider interval */
  resetSlider(): void {
    clearInterval(this.intervalId);
    this.startImageSlider();
  }
  moveToNext(event: any, nextInput: any) {
    if (event.target.value.length === 1 && nextInput) {
      nextInput.focus(); // Move focus to the next input
    }
  }

  resendCode() {
    // Disable the button and start the timer
    this.isResendDisabled = true;
    this.resendTimer = 59;

    // Start countdown
    this.interval = setInterval(() => {
      this.resendTimer--;

      if (this.resendTimer === 0) {
        this.isResendDisabled = false; // Enable the resend button after timer ends
        clearInterval(this.interval); // Stop the timer
      }
    }, 1000);

    // Simulate API call for resending OTP
    console.log('Resend OTP API called');
  }

  // verifyOTP() {
  //   const otp = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}`;
  //   console.log('Entered OTP:', otp);
  //   // Add your OTP verification logic here
  // }




  verifyOTP() {
    // this.switchView('login');

    const enteredOTP = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}`;
    console.log('Entered OTP:', enteredOTP);

    if (enteredOTP.length === 4) {
      // Redirect to the next screen
      //  console.log('OTP is valid. Redirecting...');
      alert('OTP is correct. Redirecting...');
      this.switchView('set-new-password');

    } else {
      alert('Invalid OTP. Please enter all 4 digits.');
    }


  }
  /** Cleanup when component is destroyed */
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

submitForgotPassowrdOtp(){

this.showOTPScreen = true;
}

  /** Login with OTP */
  loginWithOtp() {
    if (!this.emailOrPhonePattern.test(this.userInput)) {
      alert('Please enter a valid email or mobile number.');
      return;
    }
    console.log('Login with OTP clicked', this.userInput);
    this.switchView('verify-otp');
  }

  /** Login with Password */
  loginWithPassword() {
    console.log('Login with Password clicked', this.userInput);
    this.switchView('login-password');
  }

  /** Back to login */
  backToLogin() {
    this.switchView('login');
  }
}
