import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { DeactivateAccountComponent } from '../../deactivate-account/deactivate-account.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../../change-password/change-password.component';
import { AboutUsComponent } from '../../about-us/about-us.component';
// import { RateUsComponent } from '../../rate-us/rate-us.component';
import { TermsConditionsComponent } from '../../terms-conditions/terms-conditions.component';
import { EnquiriesComponent } from '../../enquiries/enquiries.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchService } from 'src/app/modules/home/search.service';
import { NewRateUsComponent } from '../../new-rate-us/new-rate-us.component';

@Component({
  selector: 'app-new-navbar',
  templateUrl: './new-navbar.component.html',
  standalone: true,
  imports: [CommonModule, MatIcon, MatMenuModule, MatTooltipModule],
  styleUrls: ['./new-navbar.component.scss']
})
export class NewNavbarComponent {

  @Output() iconClick = new EventEmitter<string>();
  ppic: string;
  @Output() search = new EventEmitter<string>(); // Emit search term to parent
  searchTerm: string = '';

  constructor(private router: Router,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.ppic = localStorage.getItem('loggedInPpic');
  }
// In your navbar component
// navigateTo(route: string): void {
 
//   this.iconClick.emit(route); // Emit the action to the parent component
//   console.log(route);
// }
  onIconClick(action: string) {
    this.iconClick.emit(action); // Emit the action to the parent component
    console.log(action);
  }

  onSearch(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchService.setSearchTerm(target.value); // Update search term
  }

  // onSearch(event: Event) {
  //   const target = event.target as HTMLInputElement;
  //   this.searchTerm = target.value;
  //   this.search.emit(this.searchTerm); // Emit the search term
  // }

  clearSearch() {
    this.searchTerm = '';
    // this.search.emit(this.searchTerm); // Emit empty string to clear search
    this.searchService.setSearchTerm(this.searchTerm);
  }
  async logOut() {    // Method to logout from the application
    let payload = {
      uid: this.authService.userValue.uid,
      token: this.authService.userValue['td']
    };
    console.log('logout payload', payload);
    this.spinner.show();
    (await this.authService.logout(payload)).subscribe((res) => {
      this.spinner.hide();
      this.openSnackBar(res.message, '');
      if (res.status) {
        localStorage.clear();
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });
      }
    }, error => {
      this.spinner.hide();
    });
  }

  performAction(action: string): void {
    this.iconClick.emit(action);
    console.log('Menu Action:', action);
    // switch (action) {
    //   // case 'Home':
    //   //   this.router.navigate(['/home']);
    //   //   break;
    //   case 'My Profile':
    //     this.router.navigate(['/profile'], { queryParams: { cf: 'p', id: localStorage.getItem('uid') ?? this.authService.userValue.uid } });
    //     break;
    //     // case 'Subscriptions':
    //     //   this.router.navigate(['/subscriptions'], { queryParams: { cf: 's' } });
    //     break;
    //   case 'Enquiries':
    //     // this.openDialog(EnquiriesComponent);
    //     this.router.navigate(['/enquiry-us']);

    //     break;
    //   case 'Terms and Conditions':
    //     this.openDialog(TermsConditionsComponent);
    //     break;
    //   case 'Rate Us':
    //     this.openDialog(RateUsComponent);
    //     break;
    //   case 'About Us':
    //     this.openDialog(AboutUsComponent);
    //     break;
    //   case 'Change Password':
    //     this.openDialog(ChangePasswordComponent);
    //     break;
    //   // case 'Account Privacy Settings':
    //   //   this.openDialog(DeactivateAccountComponent);
    //   //   break;
    //   case 'Privacy':
    //     this.router.navigate(['/settings'], { queryParams: { cf: 'p', id: localStorage.getItem('uid') ?? this.authService.userValue.uid } });
    //     break;
    //   //   case 'Switch Profile':
    //   //  //   this.isRole = true;
    //   //     this.router.navigate(['home'], { queryParams: { isRole: true, ln: true } });
    //   //     break;
    //   case 'Deactivate Account':
    //     this.openDialog(DeactivateAccountComponent);
    //     break;
    //   case 'Logout':
    //     this.logOut();
    //     break;

    //   default:
    //     console.warn('Unknown menu action:', action);
    // }
  }

  openSnackBar(message: string, action: string): void {
    // Method to open alert messages
    this._snackBar.open(message, action, { duration: 3000 });
  }

  openDialog(dialogType: any) {
    const dialogRef = this.dialog.open(dialogType, {
      // height: '500px',
      // width: '60%',
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((res) => {
      // this.getMyGroups();
    });
  }

}
