<div class="rate-us-container">
  <div class="popup-header">
    <h1>Rate Us</h1>
    <button class="cancel-button" (click)="closePopup()">✖</button>
  </div>
  <h2>Tell Us your Experience about Our App?</h2>

  <img src="../../../assets/follow.png" alt="">
  <textarea
    [placeholder]="feedbackPlaceholder"
    class="feedback-input"
    [(ngModel)]="feedback"
  ></textarea>
  <div class="rating-container">

    <div class="rating-item" *ngFor="let option of ratingOptions">
      <input
        type="radio"
        [id]="option.id"
        name="rating"
        [value]="option.rating"  
        [(ngModel)]="selectedRating"
        (change)="updatePlaceholder(option.rating)" 
      />
      <label [for]="option.id">{{ option.icon }}</label>
      <span>{{ option.label }}</span>
    </div>
  </div>
  <button class="submit-button" (click)="submitFeedback()">Submit</button>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-atom" [fullScreen]="true">
  <b style="color: white"> Loading... </b>
</ngx-spinner>