import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SimpleOption } from '../../profile/dialogs/professional-summary-dialog/simple-option.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndpointsService } from 'src/app/services/api-endpoint.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Output() loginClosed = new EventEmitter();

  showSpinner: boolean = false;
  showOTPSpinner: boolean = false;
  showRegisterSpinner: boolean = false;
  showPasswordSpinner: boolean = false;
  // geoCoder: google.maps.Geocoder;
  showCreatePassword: boolean = false;
  latitude: any;
  longitude: any;
  disableMobile: boolean = false;

  inviteCode = '';
  registrationHeading = "Let's get started,";

  public isOtpVerified: boolean = false;

  public showMobile: boolean = false;
  public showEmail: boolean = false;

  public showOTPBox: boolean = false;

  mobileStatusMessage: string;
  otpStatusMessage: string;
  registerStatusMessage: string = '';
  passwordStatusMessage: string;

  firstFormGroup: FormGroup;
  otpFormGroup: FormGroup;
  createPasswordForm: FormGroup;

  isPassword: boolean;
  registerForm: FormGroup;

 
  specializations: SimpleOption[] = [];
  mobileCheckResponse: any;
  registerResponse: any;
  constructor(
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    // private locationService: LocationService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private apiEndPointService: ApiEndpointsService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.registerForm = new FormGroup({
      mobile: new FormControl(''),
      mobile_code: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'
        ),
      ]),
      gender: new FormControl('', Validators.required),
      specialization: new FormControl(''),
      invitationCode: new FormControl(''),
      invitationStatus: new FormControl(''),
      deviceID: new FormControl(''),
      deviceType: new FormControl(this.authService.getDeviceType()),  // Now authService is initialized
      deviceModel: new FormControl(''),
      deviceToken: new FormControl(''),
      latitude: new FormControl('17.3850'),
      longitude: new FormControl('78.4687'),
      address: new FormControl('')
    });
  }
  
   

  ngOnInit(): void {
    // this.getSpecializations();

    this.firstFormGroup = this._formBuilder.group({
      mobile: ['', Validators.required],
      mobile_code: [''],
      user_type: ['D']
    });
    this.otpFormGroup = this._formBuilder.group({
      mobile: [''],
      OTP: ['', [Validators.required, Validators.minLength(4)]],
    });

    this.createPasswordForm = this._formBuilder.group({
      uid: [],
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    });
    // this.geoCoder = new google.maps.Geocoder();
    this.getCurrentLocation();
    let firstLoginResponse: any;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      firstLoginResponse = JSON.parse(params.firstLoginResponse);
      if (firstLoginResponse && firstLoginResponse.firstTimePassword.toLowerCase() == 'yes') {
        //create password
        if (firstLoginResponse.uid) this.createPasswordForm.get('uid').setValue(firstLoginResponse.uid);
        this.registrationHeading = 'Create Password';
        this.showCreatePassword = true;
      }
    })
  }

  generateUUIDV4(): string {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // eslint-disable-next-line no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }

  async getSpecializations() {
    let payload = {
      uid: this.authService.userValue.uid
    };
    (await this.authService.doctorSpecializations(payload)).subscribe((res: any) => {
        this.specializations = res;
      });
  }

  onCloseDrawer() {
    // this.loginClosed.emit(true);
    this.router.navigateByUrl('new-landing');
  }
  onOtpVerify() {
    this.isOtpVerified = !this.isOtpVerified;
  }

  getDeviceId() {
    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
      deviceId = this.generateUUIDV4()
      localStorage.setItem('deviceId', deviceId)
    }
    return deviceId;
  }

  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          // this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  // getAddress(latitude: any, longitude: any): void {
  //   this.geoCoder.geocode(
  //     { location: { lat: latitude, lng: longitude } },
  //     (results: { formatted_address: any }[], status: string) => {
  //       if (status === 'OK') {
  //         if (results[0]) {
  //           localStorage.setItem('deviceLocation', results[0]['formatted_address']);
  //           localStorage.setItem('latitude', latitude);
  //           localStorage.setItem('longitude', longitude);
  //         }
  //       } else {
  //         // window.alert('Geocoder failed due to: ' + status);
  //       }
  //     }
  //   );

  // }

  async register() {
    const encryptedToken = localStorage.getItem('kf');

    let deviceToken = '';
    
    if (encryptedToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'ae65f515-b859-4c7f-887c-328a3c14ab7f');
        deviceToken = bytes.toString(CryptoJS.enc.Utf8);
      } catch (e) {
        console.error('Decryption error:', e);
      }
    } else {
      console.warn('No token found in localStorage under key "kf"');
    }
    
    console.log('Decrypted device token:', deviceToken);
      const payload = {
        mobile: this.registerForm.get('mobile').value,
        mobile_code: '91',
        first_name: this.registerForm.get('firstName').value,
        last_name: this.registerForm.get('lastName').value,
        email_address: this.registerForm.get('emailAddress').value,
        gender: this.registerForm.get('gender').value,
        invitation_code: this.registerForm.get('invitationCode').value,
        invitation_status: this.mobileCheckResponse.invitation_status,
        device_type: 'Web',
        device_model: this.deviceService.getDeviceInfo().os + ' - ' + this.deviceService.getDeviceInfo().browser,
        device_token: deviceToken,
        // device_token: CryptoJS.AES.decrypt(localStorage.getItem('kf'), 'ae65f515-b859-4c7f-887c-328a3c14ab7f').toString(CryptoJS.enc.Utf8),
        device_id: this.getDeviceId(),
        latitude: this.latitude ? this.latitude.toString() : '17.3850',
        longitude: this.longitude ? this.longitude.toString() : '78.4867',
        user_type: 'D'
      };
      console.log('register payload', payload);
      this.spinner.show();
      (await this.authService.registerV3(payload)).subscribe((res) => {
        console.log(res);
        if (res.status && res.data) {
          this.spinner.hide();
          this.apiEndPointService.decodeAesToken('nushift_connect', res.data).then((data) => {
            data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
            this.registerResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
            console.log(this.registerResponse);
            if (this.mobileCheckResponse.invitation_status &&
               (
                (
                  this.mobileCheckResponse.invitation_status.toLowerCase() == 'ni' 
                  && this.mobileCheckResponse.invite_code
                )
                || this.mobileCheckResponse.invitation_status.toLowerCase() == 'nothing'
              )
                 && this.registerResponse.uid
              ) {
              this.createPasswordForm.get('uid').patchValue(this.registerResponse.uid);
              this.showCreatePassword = true;
            } else {
              this.registerStatusMessage = '';
              this.openSnackBar(res.message, 'Success');
              this.router.navigateByUrl('landing');
            }
          });
        } else {
          this.spinner.hide();
          this.openSnackBar(res.message, '');
          if (res.status && this.mobileCheckResponse.invite_code) {
            this.showCreatePassword = true;
          }
        }
      }, error => {
        this.openSnackBar('Something went wrong! Please try again', '');
        this.showSpinner = false;
      });
  }

  async mobileInviteCheck() {
    if (this.firstFormGroup.get('mobile').value.includes('@')) {
      this.firstFormGroup.get('mobile_code').setValue('');
      this.showMobile = true;
      this.showEmail = false;
      this.registerForm.get('emailAddress').setValue(this.firstFormGroup.get('mobile').value);
    } else {
      this.firstFormGroup.get('mobile_code').setValue('91');
      this.showEmail = true;
      this.showMobile = false;
      this.registerForm
        .get('mobile')
        .setValue(this.firstFormGroup.get('mobile').value);
    }
    let formData = this.firstFormGroup.value;
    this.spinner.show();
    (await this.authService
      .mobileInviteCheck(formData))
      .subscribe((res) => {
        this.spinner.hide();
        this.apiEndPointService.decodeAesToken('nushift_connect', res.data).then((data) => {
          data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
          this.mobileCheckResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
          console.log(this.mobileCheckResponse);
          if (res.status) {
            if (res.message && res.message.toLowerCase() == 'invalid otp') this.openSnackBar('Something went wrong, please refresh the page and try again', '');
            if (this.mobileCheckResponse.invitation_status || this.mobileCheckResponse.state) {
              if ((this.mobileCheckResponse.invitation_status.toLowerCase() == "nothing" ||this.mobileCheckResponse.invitation_status.toLowerCase() == "ni" || this.mobileCheckResponse.invitation_status.toLowerCase() == "wl") || this.mobileCheckResponse.state.toLowerCase() == "wl") {
                this.showOTPBox = true;
                this.openSnackBar(res.message, '');
                if (this.mobileCheckResponse.invitation_status && this.mobileCheckResponse.invitation_status.toLowerCase() == "ni") this.registerForm.get('invitationCode').setValue(this.mobileCheckResponse.invite_code);
              }
            } else if (this.mobileCheckResponse?.password?.toLowerCase() == "no") {
              this.registerStatusMessage = '';
              this.openSnackBar('Create New Password', '');
              this.createPasswordForm.get('uid').setValue(this.mobileCheckResponse.uid);
              this.registrationHeading = 'Create Password';
              this.showCreatePassword = true;
            }
          } else if (!res.status || res.message.toLowerCase() == 'please login with the initial password, sent to your email/mobile') {
            this.openSnackBar(res.message, '');
            this.router.navigateByUrl('landing');
          }
        });
      }, error => {
        this.spinner.hide();
        this.openSnackBar('Something went wrong! Please try again', '');
      });
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  async onOTPVerifyClick() {
    this.otpFormGroup
      .get('mobile')
      .setValue(this.firstFormGroup.get('mobile').value);
    const payload = {
      mobile: this.firstFormGroup.get('mobile').value,
      otp: this.otpFormGroup.get('OTP').value
    }
    let verifiedOtpResponse: any;
    this.spinner.show();
    (await this.authService.validateOTPV2(payload)).subscribe((res) => {
      this.spinner.hide();
      this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
        data ? console.log(JSON.parse("onOTPVerifyClick",data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
        verifiedOtpResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
        if (res.status) {
          this.otpStatusMessage = '';
          this.disableMobile = true;
          this.isOtpVerified = true;
          this.showOTPBox = false;
          this.openSnackBar(res.message, '');
        } else {
          this.openSnackBar(res.message, '');
          this.otpStatusMessage = res.message;
        }
      });
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wrong! Please try again', '')
    });
  }

  async onCreatePassword() {
    const updatedPassword = this.createPasswordForm.value.newPassword;
    let formData = {
      uid: this.createPasswordForm.get('uid').value,
      new_password: updatedPassword,
    };
    console.log(formData);
    let createPasswordResponse: any;
    this.spinner.show();
    (await this.authService.createPassword(formData)).subscribe((res) => {
      let registeredLogin: boolean = false;
      this.spinner.hide();
      this.apiEndPointService.decodeAesToken((this.authService && this.authService.userValue && this.authService.userValue.uid) ? this.authService.userValue.uid : 'nushift_connect', res.data).then((data) => {
        data ? console.log(JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"'))) : false;
        createPasswordResponse = data ? JSON.parse(data.replace(/'/g, '"').replace(/: None/g, ': "None"')) : [];
        console.log(createPasswordResponse);
        if (res.status) {
          this.openSnackBar(res.message, '');
          registeredLogin = true;
          // this.router.navigate(['home'], {queryParams: {isRole: registeredLogin ? true : false}});
          this.router.navigateByUrl('landing');

        } else {
          this.openSnackBar(res.message, '');
          this.passwordStatusMessage = res.message;
          this.otpStatusMessage = res.message;
        }
      });
    }, error => {
      this.spinner.hide();
      this.openSnackBar('Something went wrong! Please try again', '');
    });
  }
}
