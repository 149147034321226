<div [ngClass]="{ 'my-drawer': true, open: isOpen }" class="notification-drawer">
  <!-- Header Section -->
  <div class="d-flex align-items-start">
    <h1 class="notification-heading col-11">Notification</h1>
    <mat-icon class="float-right col-1 p-2" (click)="onClear()">highlight_off</mat-icon>
  </div>

  <!-- Tabs Navigation -->
  <nav class="tabs">
    <a class="tab" [class.active]="tabIndex === 0" (click)="tabClick(0)">Recent</a>
    <a class="tab" [class.active]="tabIndex === 1" (click)="tabClick(1)">Referral</a>
    <a class="tab" [class.active]="tabIndex === 2" (click)="tabClick(2)">Network</a>
    <a class="tab" [class.active]="tabIndex === 3" (click)="tabClick(3)">Invites</a>
    <a class="tab" [class.active]="tabIndex === 4" (click)="tabClick(4)">Podcasts</a>
  </nav>

  <!-- Filter Bar -->
  <div class="d-flex justify-content-between filter-bar">
    <div class="d-flex align-items-center filter-options">
      <label class="filter-option" [class.active]="notificationType === 'ALL'" (click)="getNotificationsList('ALL')">All</label>
            <label class="filter-option" [class.active]="notificationType === 'UNREAD'"> Unread({{ unReadCount}})</label>
    </div>
    <label class="mark-all-read" (click)="markAllAsRead()">Mark all as read</label>
    <!-- <label class="mark-all-read" >Mark all as read</label> -->
  </div>

  <!-- Notifications List -->
  <ng-container *ngIf="notifications.length > 0; else noNotifications">
    <div *ngFor="let notification of notifications" class="notification-item filter-bar1">
      <div class="row">
        <!-- Notification Icon -->
        <div class="col-2">
          <img
            [src]="notification.image || 'assets/img/notifications/followers.png'"
            alt="Notification Image"
            height="48px"
            width="48px"
            class="m-2"
          />
        </div>

        <!-- Notification Content -->
        <div class="col-10">
          <label class="notification-name">
            {{ notification.title }}
            <span class="green-dot" *ngIf="notification.isUnread"></span>
          </label>
          <label class="notification-desc" (click)="notificationClick(notification)">
            {{ notification.msg }}
          </label>
          <label class="notification-time">
            {{ notification.cd | date: 'MMM d, y h:mm a' }}
          </label>
        </div>
      </div>

      <!-- Separator -->
      <div class="separator-line"></div>
    </div>
  </ng-container>

  <!-- No Notifications Template -->
  <ng-template #noNotifications>
    <h4 class="d-flex justify-content-center align-items-center m-3 w-100" style="color: red">
      No Notifications
    </h4>
  </ng-template>
</div>
