import { FormsModule } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { MoreScreenService } from 'src/app/services/more-screen.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-rate-us',
  standalone: true,
  imports: [FormsModule, MatRadioModule, CommonModule, NgxSpinnerModule],
  templateUrl: './new-rate-us.component.html',
  styleUrls: ['./new-rate-us.component.scss']
})
export class NewRateUsComponent implements OnInit {
  feedback: string = '';
  selectedRating: string; // Default rating
  ratingOptions = [
    { id: 'excellent', label: 'Excellent', icon: '😍', rating: '5' },
    { id: 'good', label: 'Good', icon: '😊', rating: '4' },
    { id: 'normal', label: 'Normal', icon: '😐', rating: '3' },
    { id: 'confused', label: 'Confused', icon: '😕', rating: '2' },
    { id: 'bad', label: 'Bad', icon: '😭', rating: '1' },
  ];
  

  feedbackPlaceholder: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewRateUsComponent>,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private moreScreenService: MoreScreenService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    // this.updatePlaceholder(this.selectedRating); // Ensure placeholder is set
    // this.getRatingData();
  }

  /** ✅ Fetch Rating Data from API */
  async getRatingData() {
    let queryParameters = { uid: this.authService.userValue.uid };
  
    this.spinner.show();
    (await this.moreScreenService.getRatingData(queryParameters)).subscribe(
      (res: any) => {
        this.spinner.hide();
  
        if (res.status && res.data?.length > 0) {
          const ratingData = res.data[0]; // Get first item from API response
  
          setTimeout(() => {
            this.selectedRating = ratingData.rating.toString(); // Convert to string for ngModel
            console.log('wwwww', this.selectedRating);
            this.feedback = ratingData.comment || ''; // Extract feedback
            this.updatePlaceholder(this.selectedRating);
          });
        } else {
          this._snackBar.open('No rating data found.', '', { duration: 3000 });
        }
      },
      error => {
        this.spinner.hide();
        this._snackBar.open('Failed to load rating data.', '', { duration: 3000 });
      }
    );
  }
  
  
  /** ✅ Update Feedback Placeholder Based on Rating */
  updatePlaceholder(rating: string) {
    const placeholders = {
      Excellent: "Wow, you loved it! We're thrilled to exceed your expectations!",
      Good: "We're happy you enjoyed it. Let us know how we can do even better!",
      Normal: "Thank you! We'd love to hear how we can improve.",
      Confused: "We're sorry for the confusion. Please share your thoughts.",
      Bad: "We're really sorry! Please tell us what went wrong."
    };

    this.feedbackPlaceholder = placeholders[rating] || placeholders['Excellent'];
  }

  /** ✅ Submit Feedback */
  async submitFeedback() {
    if (!this.selectedRating) {
      alert('Please select a rating before submitting!');
      return;
    }
    if (!this.feedback.trim()) {
      alert('Please provide your feedback before submitting!');
      return;
    }
  
    // Find the selected rating object
    const selectedOption = this.ratingOptions.find(option => option.rating === this.selectedRating);
  
    if (!selectedOption) {
      alert('Invalid rating selection!');
      return;
    }
  
    const feedbackData = {
      uid: this.authService.userValue.uid,
      rating: selectedOption.rating, // Send numeric rating (1 to 5)
      comment: this.feedback,
      source_type: "Y",

    };
  
    console.log('Sending Feedback:', feedbackData);
  
    this.spinner.show();
    (await this.moreScreenService.postRatingData(feedbackData)).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.openSnackBar(response.message, '');
                this.dialogRef.close();
      },
      (error) => {
        this.spinner.hide();
        this._snackBar.open('Something went wrong! Please try again later.', '', { duration: 3000 });
      }
    );
  }
  
  closePopup() {
    this.dialogRef.close();
  }

  /** ✅ Snackbar Notification */
  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000, // Snackbar disappears after 3 seconds
      panelClass: ['success-snackbar'], // Custom CSS class
      horizontalPosition: 'right', // Align to the right
      verticalPosition: 'top', // Show at the top
    });
  }


}
