<div class="login-container">
  <div class="left-section">
    <div class="slider">
      <img [src]="currentImage" alt="Sliding Image" />

      <div class="indicators">
        <span *ngFor="let img of images; let i = index" [class.active]="i === imageIndex" (click)="goToSlide(i)">
        </span>
      </div>

    </div>


    <div>
      <h1>Welcome to <strong style="color: white;">Nushift Connect</strong></h1>
      <p style="color: white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius.</p>
    </div>

    <div class="networking">
      <h2>Networking</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.</p>
    </div>
  </div>

  <div class="right-section">
    <div class="one">
      <div class="logo">
        <img src="assets/NC_LOGO.png" alt="Nushift Connect Logo" />
      </div>
    </div>

    <!-- LOGIN SCREEN -->
    <ng-container *ngIf="currentView === 'login'">
      <div class="two">
        <h2>Login</h2>
        <p class="welcome-text">Welcome back, we missed you</p>

        <form>
          <label>Phone No. / E-mail</label>
          <div class="input-group mt-1">
            <input type="text" placeholder="Enter your email or phone number" [(ngModel)]="userInput" name="userInput"
              required #userInputField="ngModel" [pattern]="emailOrPhonePattern" />
            <div *ngIf="userInputField.invalid && userInputField.touched" class="error-message">
              Please enter a valid email or mobile number.
            </div>
          </div>

          <button class="btn otp-btn" type="button" (click)="switchView('verify-otp')">
            Login with OTP
          </button>

          <div class="divider">Or</div>

          <button class="btn password-btn" type="button" (click)="switchView('login-password')">
            Login with password
          </button>

          <!-- <p class="forgot-password" (click)="switchView('forgot')">Forgot password?</p> -->
        </form>

        <div class="three">
          <p class="signup-text">
            Don't have an account? <a (click)="switchView('register')">Sign Up</a>
          </p>
        </div>
      </div>
    </ng-container>

    <!-- OTP SCREEN -->
    <ng-container *ngIf="currentView === 'verify-otp'">
      <div class="two">
        <h2>OTP Verification</h2>
        <p class="welcome-text">OTP has been send to <b>****3310.</b> Enter the OTP to verify your phone number.</p>



        <form>
          <!-- <div class="input-group">
            <input type="text" placeholder="Enter OTP" [(ngModel)]="otpInput" name="otpInput" required />
          </div> -->
          <label>Enter OTP</label>

          <div class="otp-inputs">
            <input type="text" maxlength="1" [(ngModel)]="otp1" class="otp-box" (input)="moveToNext($event, otp2Ref)"
              #otp1Ref />
            <input type="text" maxlength="1" [(ngModel)]="otp2" class="otp-box" (input)="moveToNext($event, otp3Ref)"
              #otp2Ref />
            <input type="text" maxlength="1" [(ngModel)]="otp3" class="otp-box" (input)="moveToNext($event, otp4Ref)"
              #otp3Ref />
            <input type="text" maxlength="1" [(ngModel)]="otp4" class="otp-box" #otp4Ref />
          </div>

          <p class="resend">
            Didn’t Receive Code?
            <button class="resend-btn" (click)="resendCode()">Resend Code</button>
          </p>
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p *ngIf="isResendDisabled" class="timer">Resend code in {{ resendTimer }}s</p>

          <button class="btn otp-btn" type="button" (click)="verifyOTP()">
            Verify OTP
          </button>

        </form>
      </div>
    </ng-container>

    <!-- PASSWORD LOGIN SCREEN -->
    <ng-container *ngIf="currentView === 'login-password'">
      <div class="two">
        <h2>Login with Password</h2>
        <p class="welcome-text">Welcome back, we missed you</p>

        <form>
          <label>Phone No. / E-mail</label>

          <div class="input-group">
            <input type="text" placeholder="Enter your email or phone number" [(ngModel)]="userInput" name="userInput"
              required />
          </div>

          <label>Password</label>

          <div class="input-group">
            <input type="password" placeholder="Enter your password" [(ngModel)]="passwordInput" name="passwordInput"
              required />
          </div>
          <p class="forgot-password" (click)="switchView('forgot')">Forgot password?</p>

          <button class="btn otp-btn" type="button">
            Submit
          </button>


          <!-- <div class="divider">Or</div> -->

          <!-- <button class="btn password-btn" type="button" (click)="switchView('login')">
            Go back to Login option
          </button> -->
          <!-- <p>Go back to Login option</p> -->

          <p class="signup-text"><a (click)="switchView('login')">Go back to Login option</a>
          </p>

          <!-- <a (click)="switchView('login')"></a> -->
        </form>
      </div>
    </ng-container>

    <!-- FORGOT PASSWORD SCREEN -->
    <ng-container *ngIf="currentView === 'forgot'">
      <div class="two">
        <h2>Forgot Password</h2>
        <!-- <p class="welcome-text">Enter your email or phone number to reset your password</p> -->
        <form>
          <label class="mt-2">Registered Phone No. / E-mail</label>
          <div class="input-group">
            <input type="text" placeholder="Enter your email or phone number" [(ngModel)]="userInput" name="userInput"
              required />
          </div>

          <button class="btn otp-btn" type="button" (click)="submitForgotPassowrdOtp()">
            Submit
          </button>

          <!-- <div class="divider">Or</div>

          <button class="btn password-btn" type="button" (click)="switchView('login')">
            Go back to Login option
          </button> -->

          <div *ngIf="showOTPScreen">

            <form>
              <!-- <div class="input-group">
                <input type="text" placeholder="Enter OTP" [(ngModel)]="otpInput" name="otpInput" required />
              </div> -->
              <label>Enter OTP</label>

              <div class="otp-inputs">
                <input type="text" maxlength="1" [(ngModel)]="otp1" class="otp-box"
                  (input)="moveToNext($event, otp2Ref)" #otp1Ref />
                <input type="text" maxlength="1" [(ngModel)]="otp2" class="otp-box"
                  (input)="moveToNext($event, otp3Ref)" #otp2Ref />
                <input type="text" maxlength="1" [(ngModel)]="otp3" class="otp-box"
                  (input)="moveToNext($event, otp4Ref)" #otp3Ref />
                <input type="text" maxlength="1" [(ngModel)]="otp4" class="otp-box" #otp4Ref />
              </div>

              <p class="resend">
                Didn’t Receive Code?
                <button class="resend-btn" (click)="resendCode()">Resend Code</button>
              </p>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p *ngIf="isResendDisabled" class="timer">Resend code in {{ resendTimer }}s</p>

              <button class="btn otp-btn" type="button" (click)="verifyOTP()">
                Verify OTP
              </button>

            </form>

          </div>


        </form>
      </div>
    </ng-container>

    <!-- REGISTER SCREEN -->
    <ng-container *ngIf="currentView === 'register'">
      <div class="two">
        <h2>Sign Up</h2>
        <p class="welcome-text">Create a new account</p>

        <form>
          <div class="input-group">
            <input type="text" placeholder="Enter your name" [(ngModel)]="userInput" name="userInput" required />
          </div>

          <div class="input-group">
            <input type="text" placeholder="Enter your email or phone number" [(ngModel)]="userInput" name="userInput"
              required />
          </div>

          <button class="btn otp-btn" type="button">
            Sign Up
          </button>

          <div class="divider">Or</div>

          <button class="btn password-btn" type="button" (click)="switchView('login')">
            Go back to Login option
          </button>
        </form>
      </div>
    </ng-container>


    <ng-container *ngIf="currentView === 'set-new-password'">
      <div class="two">
        <h2>Set new password</h2>

        <form >
          <label>Enter new password</label>

          <div class="input-group">
            <input type="text" placeholder="new password" [(ngModel)]="userInput" name="userInput"
              required />
          </div>

          <label>Confirm new password</label>

          <div class="input-group">
            <input type="password" placeholder="Enter password again" [(ngModel)]="passwordInput" name="passwordInput"
              required />
          </div>

          <button class="btn otp-btn" type="button">
            Submit
          </button>


          <!-- <div class="divider">Or</div> -->

          <!-- <button class="btn password-btn" type="button" (click)="switchView('login')">
            Go back to Login option
          </button> -->
          <!-- <p>Go back to Login option</p> -->

          <p class="signup-text"><a (click)="switchView('login')">Go back to Login option</a>
          </p>

          <!-- <a (click)="switchView('login')"></a> -->
        </form>
      </div>
    </ng-container>
  </div>


</div>