import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';
import { AuthGuard } from './modules/auth.guard';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { RegistrationComponent } from './modules/register/registration/registration.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AboutComponent } from './components/about/about.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { GetInvitedComponent } from './modules/get-invited/get-invited/get-invited.component';
import { NewLandingComponent } from './new-landing/new-landing.component';
import { LayoutComponent } from './components/new-designs/layout/layout.component';
import { NewEnquiriesComponent } from './components/new-enquiries/new-enquiries.component';
import { NewLoginComponent } from './components/new-designs/new-login/new-login.component';
import { NewSignupComponent } from './components/new-designs/new-signup/new-signup.component';

const routes: Routes = [
  { path: '', redirectTo: '/new-landing', pathMatch: 'full' },
  { path: 'delete-account', component: DeleteAccountComponent, data: { authRequired: false } },
  { path: 'new-login', component: NewLoginComponent, data: { authRequired: false } },
  { path: 'sign-up', component: NewSignupComponent, data: { authRequired: false } },
  { path: 'terms', component: TermsComponent, data: { authRequired: false } },
  // enquiry-us
  { path: 'enquiry-us', component: NewEnquiriesComponent, data: { authRequired: false } },
  { path: 'privacy', component: PrivacyComponent, data: { authRequired: false } },
  { path: 'about', component: AboutComponent, data: { authRequired: false } },
  { path: 'register', component: RegistrationComponent, data: { authRequired: false } },
  { path: 'forgotPassword', component: ForgotPasswordComponent, data: { authRequired: false } },
  { path: 'landing', component: LandingScreenComponent, data: { authRequired: false } },
  { path: 'new-landing', component: NewLandingComponent, data: { authRequired: false } },
  { path: 'main', component: LayoutComponent, data: { authRequired: true } },
  { path: 'get-invited', component: GetInvitedComponent, data: { authRequired: false } },

  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  {
    path: 'networks',
    loadChildren: () =>
      import('./modules/networks/networks.module').then(
        m => m.NetworksModule
      ),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('././modules/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  // {
  //   path: 'groups',
  //   loadChildren: () =>
  //     import('././modules/groups/groups.module').then((m) => m.GroupsModule),
  //   canActivate: [AuthGuard],
  //   data: { authRequired: true },
  // },
  // {
  //   path: 'events',
  //   loadChildren: () =>
  //     import('././modules/events/events.module').then((m) => m.EventsModule),
  //   canActivate: [AuthGuard],
  //   data: { authRequired: true },
  // },
  {
    path: 'refer',
    loadChildren: () =>
      import('././modules/refer/refer.module').then((m) => m.ReferModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  // {
  //   path: 'jobs',
  //   loadChildren: () =>
  //     import('././modules/jobs/jobs.module').then((m) => m.JobsModule),
  //   canActivate: [AuthGuard],
  //   data: { authRequired: true },
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('././modules/dashboard/dashboard.module').then(
  //       (m) => m.DashboardModule
  //     ),
  //   canActivate: [AuthGuard],
  //   data: { authRequired: true },
  // },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('././modules/subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('././modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  }, {
    path: 'podcasts',
    loadChildren: () =>
      import('././modules/podcasts/podcasts.module').then((m) => m.PodcastsModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },
  , {
    path: 'moreoptions',
    loadChildren: () =>
      import('././modules/moreoptions/moreoptions.module').then((m) => m.MoreoptionsModule),
    canActivate: [AuthGuard],
    data: { authRequired: true },
  },

  // {
  //   path: 'settings',
  //   component: PrivacySettingsComponent,
  //   canActivate: [AuthGuard],
  //   data: { authRequired: true },
  // },

  // { path: '', redirectTo: '', pathMatch: 'full' }, // optional: redirect root to landing
  // { path: '**', redirectTo: '' }, // optional: wildcard route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
