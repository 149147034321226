import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { environment } from './models/constants';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { JobsComponent } from './modules/jobs/components/jobs/jobs.component';
import { MyManageJobsComponent } from './modules/jobs/components/my-manage-jobs/my-manage-jobs.component';
import { ApplyJobComponent } from './modules/jobs/components/apply-job/apply-job.component';
import { EnquiriesComponent } from './components/enquiries/enquiries.component';
import { RouterModule } from '@angular/router';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
// import { RateUsComponent } from './components/rate-us/rate-us.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DeactivateAccountComponent } from './components/deactivate-account/deactivate-account.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { RegistrationComponent } from './modules/register/registration/registration.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { AboutComponent } from './components/about/about.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { GetInvitedComponent } from './modules/get-invited/get-invited/get-invited.component';
import { NewLandingComponent } from './new-landing/new-landing.component';
import { LayoutComponent } from './components/new-designs/layout/layout.component';
import { LeftSidebarComponent } from './components/new-designs/left-sidebar/left-sidebar.component';
import { MainscreenComponent } from './components/new-designs/mainscreen/mainscreen.component';
import { RightSidebarComponent } from './components/new-designs/right-sidebar/right-sidebar.component';
import { NewNavbarComponent } from './components/new-designs/new-navbar/new-navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularFireModule } from '@angular/fire/compat';

import { AuthGuard } from './modules/auth.guard';
import { ServiceWorkerModule } from '@angular/service-worker'; 
import { FirebaseMessagingService } from 'src/firebase-messaging-sw';
// import { withDefaultRegisterables } from 'ng2-charts';
@NgModule({
  declarations: [
    AppComponent,
    LandingScreenComponent,
    LoginComponent,
    NotificationsComponent,
    JobsComponent,
    MyManageJobsComponent,
    ApplyJobComponent,
    EnquiriesComponent,
    TermsConditionsComponent,
    AboutUsComponent,
    ChangePasswordComponent,
    DeactivateAccountComponent,
    DeleteAccountComponent,
    RegistrationComponent,
    PrivacyComponent,
    TermsComponent,
    AboutComponent,
    ForgotPasswordComponent,
    GetInvitedComponent,
    NewLandingComponent,
    MainscreenComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
    LayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    CarouselModule,
    NewNavbarComponent,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  

  ],
  providers: [
    AuthGuard,
    // provideCharts(withDefaultRegisterables()), // Removed due to error
    FirebaseMessagingService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
